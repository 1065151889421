/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import NextError from 'next/error'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html lang="en">
      <body>
        <NextError statusCode={undefined as any} />
      </body>
    </html>
  )
}
